import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";

const util = {
  data() {
    return {
      typeChoices: {
        LONG_COURSE_SCI: 1,
        RECT: 2,
        STATION_CHANGE: 3,
        OVERALL: 4,
        UNDER_CONST: 5,
        TAX_DEDUCTION: 6,
        DCS_CONVERSION: 7,
        WEL_LOANS: 8,
        LEGAL_PROCEEDINGS: 9,
        WEL_SCHEMES: 10,
        FIN_BACKGR: 11,
        SOCIAL_BACKGR: 12,
        CUREME_REGN_STATE: 13,
        CBT_READINESS: 14,
        E_PARADE_STATE: 15,
        GUEST_ROOM: 16,
        SHUHADA_BENEFITS: 17,
        PETITIONS: 18,
        SHUHADA_CAT_WISE_OVERDUE: 19,
        SHUHADA_ORG_WISE_OVERDUE: 20,
        COI_RANK_WISE: 21,
        CO_WORKING_SPACE_IT_TRAINING: 22,
        RE_EMP_PROGRESS: 23,
        CYBER_MONITORING_SMN_ACCT_HANDLING: 24,
        CYBER_MONITORING_PUNITIVE_ACTIONS: 25,
        PATIENTS_TREND: 26,
        AHC_COMPLAINTS: 27,
        SHUHADA_GHQ_BENEFITS_OVERDUE: 28,
        SHUHADA_GOVT_BENEFITS_OVERDUE: 29,
      },
      types: [
        {
          name: "Long Course SCI",
          value: 1,
          colName: "long_course_Sel",
        },
        {
          name: "Rect",
          value: 2,
          colName: "rect",
        },
        {
          name: "Station Change",
          value: 3,
          colName: "station_change",
        },
        {
          name: "Overall",
          value: 4,
          colName: "overall",
        },
        {
          name: "Under Const",
          value: 5,
          colName: "under_const",
        },
        {
          name: "Tax deduction",
          value: 6,
          colName: "tax_deduction",
        },
        {
          name: "DCS Conversion",
          value: 7,
          colName: "dcs_conversion",
        },
        {
          name: "Wel loans",
          value: 8,
          colName: "wel_loans",
        },
        {
          name: "Legal Proceedings",
          value: 9,
          colName: "legal_proceedings",
        },
        {
          name: "Wel Schemes",
          value: 10,
          colName: "wel_schemes",
        },
        {
          name: "Fin Backgr",
          value: 11,
          colName: "fin_backgr",
        },
        {
          name: "Social Backgr",
          value: 12,
          colName: "social_backgr",
        },
        {
          name: "CureMe Regn State",
          value: 13,
          colName: "cureme_regn_state",
        },
        {
          name: "Cbt Readiness",
          value: 14,
          colName: "cbt_readiness",
        },
        {
          name: "EParade State",
          value: 15,
          colName: "eparade_state",
        },
        {
          name: "Guest Room",
          value: 16,
          colName: "guest_room",
        },
        {
          name: "Shuhada - Benefits",
          value: 17,
          colName: "shuhada_benefits",
        },
        {
          name: "Petitions",
          value: 18,
          colName: "petitions",
        },
        {
          name: "Shuhada - Cat Wise Overdue",
          value: 19,
          colName: "shuhada_cat_wise_overdue",
        },
        {
          name: "Shuhada - Org Wise Overdue",
          value: 20,
          colName: "shuhada_org_wise_overdue",
        },
        {
          name: "C of I - Rank Wise",
          value: 21,
          colName: "coi_rank_wise",
        },
        {
          name: "Co-working Space & IT Training",
          value: 22,
          colName: "co_working_space_it_training",
        },
        {
          name: "Re-emp Progress",
          value: 23,
          colName: "re_emp_progress",
        },
        {
          name: "Cyber Monitoring - SMN Acct Handling",
          value: 24,
          colName: "cyber_monitoring_smn_acct_handling",
        },
        {
          name: "Cyber Monitoring - PUNITIVE ACTIONS",
          value: 25,
          colName: "cyber_monitoring_punitive_actions",
        },
        {
          name: "Patients Trend",
          value: 26,
          colName: "patients_trend",
        },
        {
          name: "AHC Complaints",
          value: 27,
          colName: "ahc_complaints",
        },
        {
          name: "SIMS - GHQ Benefits (Overdue)",
          value: 28,
          colName: "shuhada_ghq_benefits_overdue",
        },
        {
          name: "SIMS - Govt Benefits (Overdue)",
          value: 29,
          colName: "shuhada_govt_benefits_overdue",
        },
      ],
      notificationChoices: {
        PA_UPCOMING: 1,
        FF_UPDATE: 2,
        AWT_UPDATE: 3,
        CP_UPDATE: 4,
        DASHBOARD_UPDATE: 5,
      },
    };
  },
  methods: {
    displayError(error) {
      const msgs = [];
      if (typeof error === "string") {
        msgs.push(`Error: ${error}`);
      } else if (
        !error.response ||
        error.response.status === 500 ||
        typeof error.response.data === "string"
      ) {
        msgs.push("Error: Server Error");
      } else {
        const data = error.response.data;
        for (let key in data) {
          const element = data[key];
          if (Array.isArray(element)) {
            if (typeof element[0] === "string") {
              if (key !== "msg") {
                msgs.push(`${key}: ${element[0]}`);
              } else {
                msgs.push(`${element[0]}`);
              }
            } else if (typeof element[0] === "object") {
              for (const key in element[0]) {
                if (key !== "msg") {
                  msgs.push(`${key}: ${element[0][key][0]}`);
                } else {
                  msgs.push(`${element[0][key][0]}`);
                }
              }
            }
          } else if (typeof element === "object") {
            for (const key in element) {
              if (key !== "msg") {
                msgs.push(`${key}: ${element[key][0]}`);
              } else {
                msgs.push(`${element[key][0]}`);
              }
            }
          } else {
            if (element !== "Invalid page.") {
              msgs.push(element);
            }
          }
        }
      }

      for (let i = 0; i < msgs.length; i++) {
        const msg = msgs[i];
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "BellIcon",
              text: msg,
              variant: "danger",
            },
          },
          {
            timeout: 2000,
            position: "top-center",
          }
        );
      }
    },

    isNumeric(str) {
      if (typeof str != "string") return false;
      return !isNaN(str) && !isNaN(parseFloat(str));
    },

    isChartData(type) {
      if (!type) {
        return false;
      }

      if (
        type.value === this.typeChoices.LONG_COURSE_SCI ||
        type.value === this.typeChoices.RECT ||
        type.value === this.typeChoices.STATION_CHANGE ||
        type.value === this.typeChoices.OVERALL ||
        type.value === this.typeChoices.UNDER_CONST ||
        type.value === this.typeChoices.TAX_DEDUCTION ||
        type.value === this.typeChoices.DCS_CONVERSION ||
        type.value === this.typeChoices.WEL_LOANS ||
        type.value === this.typeChoices.LEGAL_PROCEEDINGS ||
        type.value === this.typeChoices.WEL_SCHEMES ||
        type.value === this.typeChoices.FIN_BACKGR ||
        type.value === this.typeChoices.SOCIAL_BACKGR ||
        type.value === this.typeChoices.CUREME_REGN_STATE ||
        type.value === this.typeChoices.SHUHADA_CAT_WISE_OVERDUE ||
        type.value === this.typeChoices.SHUHADA_ORG_WISE_OVERDUE ||
        type.value === this.typeChoices.COI_RANK_WISE ||
        type.value === this.typeChoices.CYBER_MONITORING_PUNITIVE_ACTIONS ||
        type.value === this.typeChoices.PATIENTS_TREND ||
        type.value === this.typeChoices.AHC_COMPLAINTS
      ) {
        return true;
      }

      return false;
    },
  },
  computed: {},
};

export default util;
